import { ApolloClient, InMemoryCache, NormalizedCacheObject, HttpLink, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { persistCache, AsyncStorageWrapper } from "apollo3-cache-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
import getEnvironment from "../environment";
import { mobAlert } from "./mobx/MobAlert";
import i18n from "./i18n";
import { handleGraphQLError } from "./utils/GraphQLErrorHandler";
export default class GQLClient {
  private static instance: Promise<GQLClient>;
  private client: ApolloClient<NormalizedCacheObject>;

  private constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.client = client;
  }

  private static async initializeClient() {

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            NTA_getAppartmentCaract: {
              keyArgs: ["bookingId"],
              merge(existing = {}, incoming, { args: { bookingId } }) {
                return {
                  ...existing,
                  [bookingId]: {
                    ...existing[bookingId],
                    ...incoming
                  },
                };
              },
            },
            getAttachmentInfo: {
              keyArgs: ["bookingId"],
              merge(existing = {}, incoming, { args: { bookingId } }) {
                return {
                  ...existing,
                  [bookingId]: {
                    ...existing[bookingId],
                    ...incoming
                  },
                };
              },
            },
            NTA_getBookingById: {
              keyArgs: ["bookingId"],
              merge(existing = {}, incoming, { args: { bookingId } }) {
                return {
                  ...existing,
                  [bookingId]: {
                    ...existing[bookingId],
                    ...incoming
                  },
                };
              },
            },
          },
        },
      },
    });

    await persistCache({
      cache,
      storage: new AsyncStorageWrapper(AsyncStorage),
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          handleGraphQLError(message, locations, path, extensions);
        });
      if (networkError) {
        //console.error(`[Network error]: ${networkError}`);
        mobAlert.setError(i18n.t("errors.OFFLINE_MODE_TITLE"), i18n.t("errors.OFFLINE_MODE_TEXT"));
        // Tentez de récupérer les données depuis le cache pour la requête en cours
        const operationType = operation.query.definitions[0] ? operation.query.definitions[0].operation : null;
        const data = operationType === "query" ? cache.readQuery({ query: operation.query, variables: operation.variables }) : null;
        if (!data) {
          //console.error("Aucune donnée disponible dans le cache pour cette requête");
          mobAlert.setError(i18n.t("errors.ERROR_NETWORK_TITLE"), i18n.t("errors.ERROR_NETWORK_TEXT"));
        }
      }
    });

    // hardcoded for expo export:web
    // const URL_ENV = getEnvironment().serverUrl;
    const URL_ENV = "https://staging-admin-oiqia.geek.oiqia.net/graphql";

    const httpLink = new HttpLink({ uri: URL_ENV });
    const link = ApolloLink.from([errorLink, httpLink]);
    const client = new ApolloClient({
      link,
      cache,
      queryDeduplication: true,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
        },
        query: {
          fetchPolicy: "network-only",
        },
      },
    });

    return new GQLClient(client);
  }

  static getInstance(): Promise<GQLClient> {
    if (!this.instance) {
      this.instance = this.initializeClient();
    }
    return this.instance;
  }

  getApolloClientInstance(): ApolloClient<NormalizedCacheObject> {
    return this.client;
  }
}
